.adverse_flow {
    .nationality {
        border: 1px solid black;
        padding: 5px;
        display: inline-block;
        margin: 0px 15px 15px 0px; } }

.creditladder {
    .adverse_flow {
        .nationality {
            color: white;
            border: 1px solid white; } } }

@font-face {
    font-family: 'Poppins Light';
    src: url("../../../../fonts/Poppins-Light.ttf") format("truetype"); }

.closefield {
    .adverse_flow {
        .nationality {
            color: black;
            border: 1px solid black;
            font-family: 'Poppins Light'; } } }
