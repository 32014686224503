@font-face {
    font-family: Circular;
    src: url("../../../fonts/CircularStd-Bold.ttf"); }

$width: 680px;
$background: #fdb12e;

$heading_font: "Circular", sans-serif;
$heading_size: 42px;
$heading_color: white;
$heading_weight: 600;

$sub_heading_font: "Paralucent", sans-serif;
$sub_heading_size: 36px;
$sub_heading_color: white;
$sub_heading_weight: 500;

$content_font: "Paralucent", sans-serif;
$content_size: 20px;
$content_color: white;

$button_background: #fc3769;
$button_text: white;
$button_radius: 10px;
$button_border: none;
$button_padding: 15px 20px;
$button_size: 18px;

$box_background: white;
$box_padding: 20px;
$box_radius: 5px;

$block_heading_size: 20px;
$block_content_size: 16px;
$block_weight: 600;

.milo_wrap.milo_sprung {
    background-color: $background;

    @import "./_template"; }
