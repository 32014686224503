.applied_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    z-index: 999;

    .applied {
        transform: translate(calc(-50% - 26px), 0px);
        top: 0px;
        left: 50%;
        position: absolute;
        width: 680px;
        max-width: calc(100% - 53px);
        margin: 15px;
        background-color: white;

        .product {
            // text-align: center
            padding: 0px 15px;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px;

            .lender_logo_result {
                margin-bottom: 0px;
                margin-top: 15px; } }

        .congrats {
            // text-align: center
            font-weight: 900; }

        .next_heading {
            font-size: 28px;
            font-family: Tiempos;
            // margin-top: 30px
            padding: 0px 15px 15px; }

        .content {
            margin: 0px;
            padding: 0px; }

        .primary {
            background-color: black;
            color: white;
            font-size: 18px;
            text-transform: none;
            border-radius: 0px;
            border: 1px solid black;
            outline: none;
            padding: 10px 20px;
            width: calc(100% - 30px);
            margin: 15px 15px 20px 15px; } } }
