.free {

    .adverse_credit_container {
        background-color: white;
        color: black; }

    .adverse-credit {
        width: 680px;
        max-width: calc(100% - 30px);
        display: inline-block;
        padding: 0px 15px;

        .primary, .secondary {
            border-radius: 24px;
            font-size: 20px;
            background-color: black;
            color: white;
            margin-bottom: 10px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
            letter-spacing: 1px;
            cursor: pointer;
            padding: 12px;
            padding-left: 60px;
            padding-right: 60px;
            text-transform: uppercase;
            margin-bottom: 40px; }

        .secondary {
            background-color: white;
            color: black;
            border: 1px solid black; }

        .white {
            background-color: white;
            margin: 0px -100%;
            padding: 10px 0px;
            background-color: #dddddd;

            &:nth-child(n + 2) {
                margin: 30px -20px;
                padding-bottom: 30px; }

            .white_content {
                display: inline-block;
                width: calc(1100px - 30px);
                max-width: calc(100% - 30px);
                color: black;
                font-family: Universal;
                padding-bottom: 20px; } }

        .logo {
            margin: 10px 0px; }

        .heading {
            font-family: Tiempos;
            color: black;
            font-weight: 300;
            margin-top: 28px;
            margin-bottom: 14px;
            font-size: 40px;
            line-height: 18px;
            font-weight: 600;
            text-align: left; }

        .sub_heading {
            font-size: 30px;
            font-family: Universal;
            margin-bottom: 40px;
            font-weight: 400;
            line-height: 1.4;
            text-align: left; }

        .additional_question {
            font-size: 24px;
            line-height: 27px;
            font-family: Universal;
            color: black;
            margin-top: 20px;

            select {
                width: 300px;
                padding: 12px;
                font-size: 18px;
                margin-top: 10px;
                border: 1px solid black;
                outline: none;
                cursor: pointer;
                -webkit-appearance: none;
                background: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%), linear-gradient(to right, white, white);
                background-position: calc(100% - 21px) calc(1em + 2px), calc(100% - 16px) calc(1em + 2px), 100% 0;
                background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
                background-repeat: no-repeat; } }

        .high_level {
            font-size: 18px;
            line-height: 24px;
            font-family: Universal;
            color: black;

            .primary, .secondary {
                padding: 10px;
                width: 150px;
                margin-bottom: 10px;
                display: inline-block; } }

        .content {
            font-family: Universal;
            font-size: 15px;
            line-height: 21px;
            font-weight: 400;
            margin: 20px 0px; }

        button {
            background-color: white;
            color: black;
            border-radius: 20px;
            padding: 5px 10px;
            border: 1px solid black;
            margin: 5px;
            font-family: Universal;
            padding: 5px 2rem;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1.428571429;

            &.active {
                background-color: black;
                color: white;
                border: 1px solid black; } }

        .lender_logo {
            width: 100px;
            border-radius: 50%;
            background-color: white;
            margin: 10px;
            display: inline-block;
            // box-shadow: 0px 4px 10px 0px #8a8a8a
 }            // cursor: pointer

        .archive {
            background-color: #f0f0f0;
            margin: 15px -20px;
            padding: 10px 10px;
            margin-top: -5px; }

        .lender_section {
            text-align: left;
            display: inline-block;
            vertical-align: top;
            position: relative;
            border: 1px solid black;
            padding: 0px 10px 0px 0px;
            margin: 5px;
            border-radius: 50px;
            // box-shadow: 0px 3px 8px 0px #dbdbdb
            // cursor: pointer
            width: 300px;
            background-color: white;

            &:hover {
                background-color: #eee; }

            .sub_lender_logo {
                vertical-align: middle;
                display: inline-block;
                margin-bottom: -5px;

                .lender_logo {
                    margin: 0px;
                    box-shadow: 0px 0px 0px 0px transparent; } }

            .sub_lender {
                vertical-align: middle;
                display: inline-block;
                padding-left: 10px;
                text-align: left;
                font-family: Universal;

                .sub_lender_accordion {
                    font-size: 12px;
                    color: black;
                    display: inline-block;
                    border-bottom: 1px solid black;

                    .accordion_button {

                        &:hover {
                            background-color: transparent; } } } }

            .accordion {
                overflow: hidden; }

            .lender_reasons {
                font-size: 12px;
                text-align: left;
                padding: 15px;
                color: #838383;
                padding-bottom: 12px; } } }

    .toggle {
        display: inline-block;
        font-size: 13.3333px;
        color: black;
        border-radius: 20px;
        padding: 5px 12px 2px 12px;
        border: 1px solid black;
        margin: 5px;
        line-height: normal;
        font-family: Universal;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.428571429;

        .toggle_key {
            width: 58px;
            display: inline-block;
            cursor: default;
            vertical-align: middle;
            font-size: 10px;
            line-height: 10px;
            margin-bottom: 2px; }

        .toggle_value {
            display: inline-block;
            border-left: 1px solid rgba(black, 0.1);
            padding-left: 6px;
            margin-left: 6px;
            cursor: pointer;
            vertical-align: baseline;
            margin-bottom: 2px; } } }
