.slick-slider {
    width: 100%!important;
    text-align: center;
    margin-top: 20px; }

.slick-next:before, .slick-prev:before {
    color: black!important;
    font-size: 24px!important; }

.slick-next:hover, .slick-prev:hover {
    border: none;
    outline: none; }

.slick-next {
    right: 10px!important;
    width: 32px;
    height: 32px; }

.slick-prev {
    left: 10px!important;
    z-index: 1;
    width: 32px;
    height: 32px; }

.slick-slider {
    width: calc(100% - 30px);
    display: inline-block; }

.project_image {
    width: calc(100% - 80px);
    margin: 0px 40px;
    border: 1px solid black; }

@media (min-width: 900px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 300px; } } } } } } } }

@media (min-width: 700px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 250px; } } } } } } } }

@media (min-width: 600px) and (max-width: 700px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 200px; } } } } } } } }

@media (max-width: 460px) {
    #projects {
        .section {
            .sub_section {
                .sub_content {
                    .slideshow {
                        .img_wrap {
                            .project_image {
                                height: 100px; } } } } } } } }
