$width: 1020px;
$background: #141432;

$heading_font: Lato, sans-serif;
$heading_size: 42px;
$heading_color: #ffed00;
$heading_weight: 600;

$sub_heading_font: Lato, sans-serif;
$sub_heading_size: 36px;
$sub_heading_color: white;
$sub_heading_weight: 500;

$content_font: Lato, sans-serif;
$content_size: 20px;
$content_color: white;

$button_background: #5e00fa;
$button_text: white;
$button_radius: 30px;
$button_border: 1px solid white;
$button_padding: 12px 80px;
$button_size: 20px;

$box_background: white;
$box_padding: 20px;
$box_radius: 5px;

$block_heading_size: 20px;
$block_content_size: 16px;
$block_weight: 600;

body {
 }    // background-color: $background

.milo_wrap.milo_creditladder {
    background-color: $background;

    @import "./_template"; }
