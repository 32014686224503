.milo {
    width: $width;
    max-width: 100%;
    font-family: $content_font;

    .cta {
        .header {
            width: calc(#{$width} / 2 - 30px);

            .top {
                font-size: $heading_size;
                line-height: calc(#{$heading_size} + 8px);
                color: $heading_color;
                font-family: $heading_font;
                font-weight: $heading_weight; }

            .bottom {
                color: $content_color;
                font-family: $content_font;
                font-size: $content_size;
                line-height: calc(#{$content_size} + 8px); }

            .apply_btn {
                font-family: $content_font;
                text-transform: none;
                background-color: $button_background;
                color: $button_text;
                border-radius: $button_radius;
                border: $button_border;
                padding: $button_padding;
                font-size: $button_size;
                cursor: pointer; } }

        .images {
            width: calc(#{$width} / 2 - 30px); } }

    .milo_benefits {
        margin: 15px;
        text-align: left;

        .header {
            color: $sub_heading_color;
            font-family: $sub_heading_font;
            font-size: $sub_heading_size;
            font-weight: $sub_heading_weight;
            line-height: calc(#{$sub_heading_size} + 8px);
            margin-bottom: 30px; }

        .box {
            background-color: $box_background;
            padding: $box_padding;
            width: calc(100% - #{$box_padding} * 2);
            border-radius: $box_radius;
            font-family: $content_font;

            .column {
                width: 50%;
                display: inline-block;
                vertical-align: top;

                .block {
                    margin: 20px 0px;
                    padding-right: 15px;

                    .heading {
                        font-size: $block_heading_size;
                        line-height: calc(#{$block_heading_size} + 8px);
                        font-weight: $block_weight;
                        margin-bottom: 5px; }

                    .body {
                        font-size: $block_content_size;
                        line-height: calc(#{$block_content_size} + 8px); } } }

            .apply_btn {
                font-family: $content_font;
                text-transform: none;
                background-color: $button_background;
                color: $button_text;
                border-radius: $button_radius;
                border: $button_border;
                padding: $button_padding;
                font-size: $button_size;
                cursor: pointer; } } }

    .milo_section {
        margin-top: 50px; }

    .milo_rates {
        margin: 50px 15px 15px;
        text-align: left;

        .header {
            color: $sub_heading_color;
            font-family: $sub_heading_font;
            font-size: $sub_heading_size;
            font-weight: $sub_heading_weight;
            line-height: calc(#{$sub_heading_size} + 8px);
            margin-bottom: 30px; } } }
