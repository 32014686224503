.calculator_container {
    width: calc(100% - 30px);
    max-width: 680px;
    text-align: center;
    padding-bottom: 0px;
    display: inline-block;

    #calculator {
        max-width: calc(100%);
        margin: 0px;
        text-align: left;

        h1 {
            font-family: Tiempos;
            color: black;
            font-weight: 300;
            margin-top: 28px;
            margin-bottom: 14px;
            font-size: 22px;
            line-height: 28px;
            font-weight: 600;
            text-align: left; }

        table {
            width: 100%;
            font-size: 24px;
            margin-top: 20px; }

        td:nth-child(odd) {
            text-align: right;
            width: 60%; }

        td:nth-child(even) {
            text-align: left;
            font-weight: 700; }

        button {
            font-size: 16px;
            padding: 15px;
            width: calc(100% / 2);
            margin: 0px;
            border-radius: 0;
            border: 1px solid black;
            margin-bottom: -1px;
            background-color: #f0f0f0;
            outline: none;
            text-align: left;
            display: inline-block;
            filter: none;
            text-transform: none; }

        .activeRadio {
            background-color: black;
            color: white; }

        .priceInput {
            font-size: 18px;
            padding: 15px;
            width: calc(100% - 33px);
            border-radius: 0;
            border: 1px solid black;
            outline: none;
            margin-bottom: 15px;
            text-align: left; }

        .inputLabel {
            text-align: left;
            font-size: 18px;
            line-height: 24px; }

        .purchaseSelect {
            margin-top: 15px;
            margin-bottom: 15px;
            width: 100%; }

        .input {
            margin-top: 15px;
            border: none;
            padding: 0px; }

        .radio:nth-child(even) {
            margin-left: -1px; }

        .input, .output {
            display: inline-block;
            vertical-align: middle;
            // margin: 15px
            width: 100%;
            max-width: 100%; }

        .output {
            width: 500px;
            display: inline-block;
            text-align: left;
            margin-top: 15px; }

        .outputBlock {
            border-bottom: 1px solid black;
            margin-bottom: 15px; }

        .outputHeading {
            font-size: 18px;
            text-transform: uppercase;
            line-height: 14px; }

        .outputExplain {
            font-size: 12px;
            font-style: italic; }

        .outputPrice {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 2px;
            font-family: Tiempos; }

        .totalSavings {
            font-size: 50px;
            line-height: 50px;
            text-decoration: underline;
            text-underline-offset: 5px; } } }

.calculator_container.creditladder {
    background-color: #141432;
    max-width: 100%;

    #calculator {
        width: 1050px;
        // max-width: calc(100% - 30px)

        h1 {
            font-family: Lato, sans-serif;
            color: #ffed00; }

        .inputLabel, .outputHeading {
            color: white;
            font-size: 18px;
            font-family: Lato, sans-serif;
            margin-bottom: 20px;
            font-weight: 400;
            line-height: 1.4;
            text-align: left;
            text-transform: none;
            letter-spacing: normal;

            &.first {
                margin-bottom: 0px; } }

        .outputExplain {
            font-family: Lato, sans-serif;
            color: #00dfbc;
            font-size: 14px; }

        .totalSavings {
            font-size: 50px;
            line-height: 50px;
            text-decoration: underline;
            text-underline-offset: 5px;
            color: #ffed00;
            font-family: Lato, sans-serif; }

        .activeRadio {
            background-color: #5e00fa;
            color: white; }

        button {
            border: 1px solid #141432;
            color: #141432; } } }

.calculator_container.nude {
    background-color: #f3dcc4;
    max-width: 100%;

    #calculator {
        .radio {
            background-color: #f3dcc4;
            color: black; }

        .activeRadio {
            background-color: #efa07c;
            color: black; } } }

.calculator_container.nrla {
    background-color: white;
    max-width: 100%;

    #calculator {
        h1 {
            font-family: "source sans pro","helvatica","arial","sans-serif"; }

        .radio {
            background-color: black;
            color: white;
            font-weight: 900;
            border-color: white; }

        .activeRadio {
            background-color: #bed600;
            color: white; }

        .totalSavings {
            color: black;
            font-weight: 900;
            font-family: "source sans pro","helvatica","arial","sans-serif";
            margin: 10px 0px;
            font-size: 60px; } } }

@font-face {
    font-family: 'Proxima Nova Light';
    src: url("../../fonts/ProximaNova-Light.otf") format("opentype"); }

.calculator_container.vesta {
    background-color: white;
    background-image: none;
    max-width: 100%;

    #calculator {
        h1 {
            font-family: 'Proxima Nova Light'; }

        .radio {
            background-color: white;
            color: #094d5d; }

        .activeRadio {
            background-color: #18a4a8;
            color: white; }

        .totalSavings {
            color: #18a4a8;
            font-family: 'Proxima Nova Light'; } } }

@font-face {
    font-family: 'Poppins Regular';
    src: url("../../fonts/Poppins-Bold.ttf") format("truetype"); }

.calculator_container.closefield {
    background-color: white;
    min-height: 0px;
    max-width: 100%;

    #calculator {
        h1 {
            font-family: 'Poppins Regular';
            color: #001930; }

        .radio {
            background-color: #e5b4aa;
            color: #001930; }

        .activeRadio {
            background-color: #001930;
            color: #e5b4aa; }

        .totalSavings {
            font-family: 'Poppins Regular';
            color: #001930; } } }
