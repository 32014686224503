#chat {

    #live-chat-widget {
        bottom: 20px;
        right: 20px; }

    #chat_x {
        display: none;
        position: fixed;
        top: 0px;
        right: -16px;
        font-size: 20px;
        background-color: white;
        padding: 30px;
        z-index: 10001; }

    .chat_widget {
        position: fixed;
        bottom: 20px;
        right: 20px;
        // background-color: white
        z-index: 10001;

        #popup {
            width: 96px;
            height: 96px;
            background-color: transparent;
            position: relative;

            #hem_face {
                background-color: black;
                border-radius: 50%;
                width: 76px;
                height: 76px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url("../img/Ray2.png");
                background-size: 100% auto;
                background-position-x: 2px;
                cursor: pointer;
                border: 1px solid black; }

            #chat_cta_wrap {
                transform: translate(-100%, -50%);
                left: 0px;
                top: 50%;
                position: absolute;
                width: 232px;
                overflow: hidden;
                max-width: 0px;
                transition: 0.4s max-width ease-in-out;

                #chat_cta {
                    width: 200px;
                    border: 1px solid black;
                    padding: 10px;
                    background-color: white;
                    border-radius: 5px;
                    position: relative;

                    #book, #whatsapp {
                        padding: 15px 10px;
                        border-radius: 5px;
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%;
                        border: none;
                        cursor: pointer;
                        font-size: 14px; }

                    #book {
                        background-color: black;
                        color: white; }

                    #whatsapp {
                        background-color: #25D366;
                        color: white;

                        .whatsapp_logo {
                            width: 26px;
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 5px; }

                        #text {
                            display: inline-block;
                            vertical-align: middle;
                            max-width: calc(100% - 26px);
                            line-height: 15px; } }

                    #arrow {
                        transform: rotate(45deg) translate(0%, -50%);
                        border-top: 1px solid black;
                        border-right: 1px solid black;
                        border-top-right-radius: 2px;
                        width: 10px;
                        height: 10px;
                        background-color: white;
                        position: absolute;
                        right: -3px;
                        top: 50%; } } } } }

    @media screen and ( max-width: 450px ) {
        #live-chat-widget {
            bottom: 0px!important;
            right: 0px!important; } }

    @media screen and ( max-width: 451px ) {
        .chat_widget {
            bottom: 0px;
            right: 0px; } } }
