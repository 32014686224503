@font-face {
    font-family: Circular;
    src: url("../../../fonts/CircularStd-Bold.ttf"); }

// COLOURS
$orange: #fdb12e;
$red: #fc3769;

$background: #fdb12e;
$heading: white;
$content: white;
$back: white;

$radio_background: #fdb12e;
$radio_text: white;

$input_background: rgba(white, 0.8);
$input_text: black;

$button_background: #fc3769;
$button_text: white;

$heading_font: "Circular", sans-serif;
$content_font: "Paralucent", sans-serif;

$disabled: rgba(white, 0.8);
$greyed_out: #999;
$light_grey: #bbb;

$progress: #fc3769;
$progress_greyed: black;

$modal_page: #fdb12e;
$modal_background: #fdb12e;
$modal_heading: white;
$modal_content: white;

$sub_color: black;
$info_color: #fc3769;
$active_color: #fc3769;

$collection_row1: white;
$collection_row2: black;
$collection_border: #fc3769;
$collection_add: white;

$result_grey_box: #f0f0f0;

$width: 680px;

.sprung {
    width: 100%;
    min-height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";
        @import "../../Application/_application";

        .modal {
            .modalContent {
                input {
                    border-radius: 20px;
                    border: none; }

                .capBtn {
                    border-radius: 20px;
                    font-weight: 500;
                    padding: 15px 20px;
                    border: none;
                    text-transform: capitalize;
                    letter-spacing: 2px; } } }

        .navbar {
            .back {
                .backArrow {
                    filter: brightness(3); } } }

        .input_contain {
            .input_wrap {
                input {
                    border-radius: 20px;
                    border: none; }

                select {
                    border-radius: 20px;
                    border: none; } }

            .capBtn {
                border-radius: 20px;
                font-weight: 500;
                padding: 15px 20px;
                border: none;
                text-transform: capitalize;
                letter-spacing: 2px;

                &:disabled {
                    border: none; } } }

        .collection {
            .capBtn {
                border-radius: 20px;
                font-weight: 500;
                padding: 15px 20px;
                border: none;
                text-transform: capitalize;
                letter-spacing: 2px; } }

        .radio.small {
            border-radius: 20px;
            border: none; }

        .section_divider {
            .section_overview {
                border: 1px solid #03C896;
                filter: invert(100%);

                .text {
                    .bottom {
                        .timeframe {
                            color: $light_grey; } } } }

            .subheading {
                color: $active_color; }

            .capBtn {
                border-radius: 20px;
                font-weight: 500;
                padding: 15px 20px;
                border: none;
                text-transform: capitalize;
                letter-spacing: 2px; } }

        .lender_table {
            color: black;
            background-color: white;
            border: 1px solid #fc3769;

            tbody {
                .accordion_tr {
                    border-color: #fc3769;

                    .product_container {
                        .product_bubble {
                            border-color: #fc3769; } } }

                tr {
                    td {
                        .apply_btn_alt {
                            background-color: #fc3769;
                            color: white;
                            border-radius: 20px; } } } } } } }
