@font-face {
    font-family: 'Proxima Nova Light';
    src: url("../../../fonts/ProximaNova-Light.otf") format("opentype"); }

@font-face {
    font-family: 'Proxima Nova';
    src: url("../../../fonts/ProximaNova-Regular.otf") format("opentype"); }

@font-face {
    font-family: 'Proxima Nova Semi Bold';
    src: url("../../../fonts/ProximaNova-Semibold.otf") format("opentype"); }

$background: #f4f5f5;
$heading: #18a4a8;
$content: #094d5d;
$back: #999;

$radio_background: white;
$radio_text: #094d5d;

$input_background: white;
$input_text: black;

$button_background: #fd682b;
$button_text: white;

$heading_font: Proxima Nova Light;
$content_font: Proxima Nova Light;

$disabled: #dddddd;
$greyed_out: grey;
$light_grey: #094d5d;

$progress: #fd682b;
$progress_greyed: #094d5d;

$modal_page: #f4f5f5;
$modal_background: white;
$modal_heading: #18a4a8;
$modal_content: #094d5d;

$sub_color: #fd682b;
$info_color: grey;
$active_color: #18a4a8;

$collection_row1: black;
$collection_row2: grey;
$collection_border: grey;
$collection_add: black;

$result_grey_box: #f0f0f0;

$width: 1268px;

.vesta {
    width: 100%;
    min-height: 100vh;
    background-color: $background;
    background-image: url("https://images.prismic.io/vesta-property-prod%2Ffd79ca81-0c89-4b76-931d-9d67d08d0dd9_pattern-02-light-grey-bg.svg?auto=compress,format");

    .adverse_flow {
        background-color: transparent;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";
        @import "../../Application/_application";

        .modal {
            background-image: url("https://images.prismic.io/vesta-property-prod%2Ffd79ca81-0c89-4b76-931d-9d67d08d0dd9_pattern-02-light-grey-bg.svg?auto=compress,format"); }

        .lender_table {
            border: none;

            tbody {
                .accordion_tr {
                    border-color: $background;

                    .product_container {
                        .product_bubble {
                            background-color: $background; } } } } }

        .navbar {
            .progress_bar {
                background-color: transparent; } }

        .radio.small {
            width: 196px; }

        .section_divider {
            .heading {
                font-size: 48px;
                font-weight: 300; }

            .subheading {
                font-weight: 600; }

            .content {
                font-weight: 600;
                font-size: 16px; }

            .section_overview {
                border: 1px solid #094d5d;

                .text {
                    font-size: 16px;
                    color: #094d5d;

                    .top {
                        font-weight: 600; } } } }

        .capBtn {
            border: none;
            font-weight: 700;
            width: 300px;
            font-size: 16px; }

        .input_contain {
            .capBtn:disabled {
                border: none; } } } }
