.acre_results {
    .term_changer {
        box-shadow: 0 0 0 1px black;
        border-radius: 5px;
        margin: 0px 15px;
        margin-top: 25px;
        margin-bottom: 30px;
        position: sticky;
        top: 70px;
        background-color: white;
        z-index: 1;

        .term {
            display: inline-block;
            width: calc(100% / 3);
            text-align: center;
            border-left: 1px solid black;
            margin-left: -1px;
            height: 50px;
            line-height: 50px;
            cursor: pointer;
            background-color: white;

            &.active {
                background-color: black;
                color: white; }

            &:nth-last-child(1) {
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px; } } }

    .no_results {
        margin: 60px 30px 30px;
        font-size: 20px;
        font-weight: 900;
        font-family: Tiempos;
        text-align: center; }

    .protection_wrap, .gi_wrap {
        margin-right: 7.5px;
        margin-left: 15px;
        width: calc(50% - 54.5px);
        border: 1px solid black;
        padding: 15px;
        display: inline-block;
        margin-bottom: 15px;

        .protection, .gi {
            text-align: center;
            font-size: 20px;
            font-weight: 900;
            position: relative;

            .loader {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1; }

            .title {
                font-size: 16px; }

            .sub {
                font-size: 14px;
                font-weight: 300;
                margin-top: 5px; }

            .small_text {
                font-size: 11px;
                font-weight: 300;
                line-height: 14px;
                margin: 15px 15px 0px; } } }

    .gi_wrap {
        margin-right: 15px;
        margin-left: 7.5px; }

    .result_table {
        width: 100%;
        border-collapse: collapse;

        thead {
            tr {
                &:nth-child(n+2) {
                    td {
                        background-color: #aaa;
                        text-align: center;
                        font-weight: 900;
                        padding: 0px 10px 10px; } }

                td {
                    background-color: #aaa;
                    text-align: center;
                    font-weight: 900;
                    padding: 10px 0px 0px;
                    border-right: 1px solid #9d9d9d;

                    &:nth-last-child(1) {
                        border-right: none; } } } }

        tbody {
            tr {
                position: relative;

                .extra_products {
                    padding-bottom: 30px;

                    .product_extra {
                        display: inline-block;
                        vertical-align: top;
                        // border: 1px solid blue
                        margin: 5px;
                        padding: 5px;
                        box-shadow: 0px 3px 10px 0px #aaa;
                        background-color: white;

                        .topleft, .bottomleft, .topright, .bottomright {
                            display: inline-block;
                            vertical-align: top;
                            // width: calc(50% - 10px)
                            margin: 10px;
                            font-weight: 400;

                            .extraheading {
                                font-size: 20px;
                                margin: 0px;
                                padding: 0px;
                                font-weight: 900; } }

                        .topleft, .topright {
                            width: calc(50% - 20px); }

                        .topleft, .bottomleft {
                            text-align: left; }

                        .topright, .bottomright {
                            text-align: right; }

                        .bottomright {
                            font-weight: 900; } } }

                .buttons_wrap {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;

                    .buttons_wrap_inner {

                        .accordion_button {
                            color: black;
                            position: relative;
                            margin-right: 5px;

                            .text {
                                margin-right: 20px; }

                            .chevron {
                                top: 13px;
                                right: -5px; } }

                        button {
                            font-size: 14px;
                            background-color: black;
                            border-radius: 5px;
                            color: white;
                            padding: 5px 10px;
                            margin: 0px;
                            transition: none;

                            &:disabled {
                                background-color: #c0c0c0;
                                color: #808080;
                                border-color: #c0c0c0;
                                cursor: auto; } } } }

                td {
                    text-align: center;
                    vertical-align: top;
                    padding: 10px;
                    position: relative;

                    .aff_icon {
                        font-weight: 900;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 22px; }

                    .lender_logo_result {
                        mix-blend-mode: multiply;
                        margin-bottom: 0px;
                        margin-top: 0px;
                        vertical-align: middle;
                        width: 84px;
                        height: 35px;
                        max-width: 180px; }

                    button {
                        font-size: 14px;
                        background-color: black;
                        border-radius: 5px;
                        color: white;
                        padding: 5px 10px;
                        margin: 10px 0px 0px;
                        transition: none;

                        &:disabled {
                            background-color: #c0c0c0;
                            color: #808080;
                            border-color: #c0c0c0;
                            cursor: auto; } }

                    &:nth-last-child(1) {
                        font-weight: 900; }

                    table {
                        tbody {

                            tr {
                                &:nth-last-child(1) {
                                    td {
                                        cursor: pointer;
                                        text-align: right;
                                        padding: 0px;
                                        padding-top: 5px; } } } } } }

                // &:nth-child(4n), &:nth-child(4n - 1)
 }                //     background-color: #d4d4d4

            .new_results_row {
                td {
                    text-align: left;

                    &:nth-child(n+2) {
                        text-align: center; } } } } }

    .update_sourcing {
        margin: 0px 15px 15px;
        text-align: right;

        .update_sourcing_button {
            font-size: 14px;
            background-color: black;
            border-radius: 5px;
            color: white;
            padding: 5px 10px;
            margin: 0px;
            transition: none; } }

    .stats_table {
        margin: 15px;
        width: 100%;

        thead {
            tr {
                td {
                    font-size: 12px; } } }

        tbody {
            tr {
                td {
                    font-weight: 900;

                    .stat_div {
                        &:focus-within {
                            .stat_label_start {
                                outline: 2px solid black; }
                            .stat_input_div {
                                outline: 2px solid black; }
                            .stat_label_end {
                                outline: 2px solid black; } }

                        .stat_label_start {
                            background-color: black;
                            color: white;
                            padding: 10px;
                            display: inline-block;
                            font-size: 16px;
                            line-height: 16px;
                            vertical-align: top;
                            border: 1px solid black;
                            border-top-left-radius: 5px;
                            border-bottom-left-radius: 5px; }

                        .stat_input_div {
                            display: inline-block;
                            vertical-align: top;
                            border: 1px solid black;
                            width: 100px;
                            font-size: 16px;
                            line-height: 16px;
                            height: 36px;

                            .stat_input {
                                font-size: 16px;
                                padding: 10px 10px 0px;
                                line-height: 16px;
                                height: auto;
                                border: none;
                                background-color: transparent; } }

                        .stat_label_end {
                            background-color: black;
                            color: white;
                            padding: 10px;
                            display: inline-block;
                            font-size: 16px;
                            line-height: 16px;
                            vertical-align: top;
                            border: 1px solid black;
                            border-top-right-radius: 5px;
                            border-bottom-right-radius: 5px; } } } } } }

    .insurance_table {
        margin: -5px 15px 30px;
        width: 100%;
        position: relative;

        .loader {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1; }

        tbody {
            tr {
                font-size: 18px;
                font-weight: 900;
                position: relative;

                td {
                    &:nth-child(n+2) {
                        text-align: center; } }

                &:nth-child(2n-1) {
                    font-size: 14px;
                    font-weight: 300;
                    margin-top: 5px;

                    td {
                        padding-top: 5px; } } } } }

    .loadmore_container {
        text-align: center;

        .loadmore_text {
            margin-top: 30px; }

        .loadmore {
            border-radius: 2px;
            padding: 10px 15px;
            font-size: 18px;
            background-color: black;
            color: white; } } }

@media screen and ( max-width: 680px ) {
    .acre_results {
        .stats_table {
            table-layout: fixed;
            width: calc(100% - 45px); }

        .insurance_table {
            width: calc(100% - 45px);
            table-layout: fixed;

            tbody {
                tr {
                    font-size: 18px;
                    font-weight: 900;
                    position: relative;

                    td {
                        &:nth-child(n+2) {
                            text-align: center; } }

                    &:nth-child(1) {
                        font-size: 18px;
                        font-weight: 900;
                        position: relative; }

                    &:nth-child(3) {
                        td {
                            padding-bottom: 30px; } }

                    &:nth-child(3n-1) {
                        font-size: 14px;
                        font-weight: 300;
                        margin-top: 5px;
                        text-align: left;

                        td {
                            padding-top: 5px;
                            text-align: left; } }

                    &:nth-child(3n) {
                        font-size: 18px;
                        font-weight: 900;

                        td {
                            text-align: left; } } } } } } }

@media screen and ( max-width: 500px ) {
    .acre_results {
        .result_table {
            thead {
                font-size: 14px!important; }

            tbody {
                tr {
                    td {
                        font-size: 12px!important;

                        .aff_icon {
                            font-size: 12px; }

                        .lender_logo_result {
                            width: 42px;
                            height: 17.5px; }

                        table {
                            tbody {
                                line-height: 12px!important;

                                tr {
                                    &:nth-last-child(1) {
                                        td {
                                            text-align: right;
                                            padding: 0px;
                                            padding-top: 5px; } } } } } } } } } } }

@media screen and ( max-width: 400px ) {
    .acre_results {
        .result_table {
            thead {
                font-size: 12px!important; }

            tbody {
                tr {
                    td {
                        font-size: 10px!important;

                        .aff_icon {
                            font-size: 12px; }

                        .lender_logo_result {
                            width: 42px;
                            height: 17.5px; }

                        table {
                            tbody {
                                line-height: 12px!important;

                                tr {
                                    &:nth-last-child(1) {
                                        td {
                                            text-align: right;
                                            padding: 0px;
                                            padding-top: 5px; } } } } } } } } } } }
