.content {
    .primary {
        background-color: white;
        font-size: 18px;
        text-transform: none;
        border-radius: 0px;
        border: 1px solid black;
        outline: none;
        padding: 10px 20px;
        // width: calc(50% - 5px)
        width: 100%;
        margin: 15px 5px 20px 0px; }

    .whatsapp {
        background-color: #25D366;
        color: white;
        font-size: 18px;
        text-transform: none;
        border-radius: 0px;
        border: 1px solid black;
        outline: none;
        padding: 10px 20px;
        width: calc(50% - 5px);
        margin: 15px 0px 20px 5px; } }

.underline {
    text-decoration: underline;
    text-underline-offset: 2px; }

.grey_box {
    background-color: $result_grey_box;
    padding: 15px;
    padding-bottom: 105px;
    margin-bottom: -115px;
    border-top: 2px solid $radio_text;

    .content {
        text-align: center; } }

.deposit_changer {
    // border: 1px solid black
    box-shadow: 0 0 0 1px black;
    border-radius: 5px;
    margin: 0px 15px;
    margin-top: 25px;
    margin-bottom: 30px;
    position: sticky;
    top: 70px;
    background-color: $radio-background;
    z-index: 1;

    .deposit_value {
        position: absolute;
        top: -55px;
        background-color: $background;
        color: $heading;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid $heading;
        min-width: 160px;
        text-align: center; }

    .deposit_arrow {
        position: absolute;
        top: -16px;
        height: 10px;
        width: 10px;
        border-bottom-right-radius: 2px;
        transform: rotate(45deg);
        border-right: 2px solid $heading;
        border-bottom: 2px solid $heading;
        background-color: $background; }

    .deposit {
        display: inline-block;
        width: calc(100% / 6);
        text-align: center;
        border-left: 1px solid black;
        margin-left: -1px;
        // margin-bottom: -1px
        // margin-top: -1px
        height: 50px;
        line-height: 50px;
        cursor: pointer;
        background-color: white;

        &.disabled {
            background-color: $disabled;
            color: $greyed_out;
 }            // cursor: initial

        &.active {
            background-color: $button_background;
            color: $button-text; }

        &:nth-child(1) {
            margin-left: 0px;
            border-left: none;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px; }

        &:nth-last-child(1) {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px; } } }

.lender_table {
    border-collapse: collapse;
    border: 1px solid $radio_text;
    width: 100%;
    margin: -5px 0px 30px 0px;
    margin-bottom: 0px;
    background-color: $radio_background;
    color: $radio-text;

    thead {
        width: calc(100% - 30px);

        tr {
            background-color: $button_background;
            color: $button_text;
            line-height: 30px;

            td {
                width: calc((50% - 30px) / 3);
                text-align: center;
                padding-right: 15px;

                &:nth-child(1) {
                    width: calc(50% - 30px);
                    padding-left: 15px;
                    padding-right: 0px; }

                &:nth-last-child(1) {
                    padding-right: 15px; } } } }

    tbody {
        .accordion {
            // border-bottom: 1px solid $radio_text
            width: 100%; }

        .accordion_tr {
            border-bottom: 1px solid $radio_text;

            .product_heading {
                font-size: 20px;
                font-weight: 600;
                text-align: center; }

            .product_container {
                text-align: center;
                padding: 0px 15px 15px;

                .product_bubble {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 5px;
                    border: 1px solid $radio_text;
                    border-radius: 5px;
                    margin: 5px;

                    .term, .rate {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 5px;

                        .number {
                            font-size: 20px;
                            font-weight: 600;
                            text-align: center;
                            line-height: 20px;
                            margin: 0px 0px 5px; }

                        .text {
                            font-size: 12px;
                            line-height: 12px;
                            font-weight: 600;
                            color: $light_grey; } }

                    .apply_btn, .apply_btn_alt {
                        border-radius: 5px;
                        margin: 0px; } } } }

        tr {
            // border-bottom: 1px solid $radio_text
            line-height: 50px;

            &:nth-child(1) {
                border-top: 1px solid $radio_text; }

            td {
                width: calc(50% / 3);
                text-align: center;
                font-size: 14px;
                padding-right: 15px;

                &:nth-child(1) {
                    width: 50%;
                    padding-left: 15px;
                    padding-right: 0px;
                    text-align: left; }

                &:nth-last-child(1) {
                    padding-right: 15px;
                    width: calc(50% / 3 + 15px); }

                .no_rate {
                    transform: scale(0.7) rotate(45deg);
                    margin: 0px 0px -14px 0px; }

                .apply_btn, .apply_btn_alt {
                    background-color: $button_background;
                    border: none;
                    color: $button_text;
                    text-transform: none;
                    font-size: 14px;
                    width: 100px;
                    padding: 5px;
                    margin: 0px 15px 5px; }

                .apply_btn_alt {
                    background-color: $button_text;
                    color: $button_background;
                    border: 1px solid $button_background; } } } } }

.table_replace {
    font-size: 18px;
    line-height: 100px;
    text-align: center;
    margin-bottom: -30px;
    background-color: $background;
    color: $content; }

ul {
    li {
        line-height: 40px;
        list-style-image: url("../../../img/icon-tick.svg");
        padding-left: 10px; } }

.subheading {
    padding: 0px 15px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    color: $content; }

.result_button {
    background-color: $button_background;
    color: $button-text;
    text-transform: none;
    width: calc(100% - 30px);
    font-size: 18px;
    border-radius: 0px;
    margin: 0px 15px;
    margin-bottom: 20px; }

.alt_result_button {
    background-color: $button_text;
    color: $button-background;
    text-transform: none;
    width: calc(100% - 30px);
    font-size: 18px;
    border-radius: 0px;
    margin: 0px 15px;
    margin-bottom: 20px; }

.table_acc_button {
    border-bottom: 1px solid $content;
    display: inline-block;

    .accordion_button {

        &:hover {
            background-color: transparent; }

        .text {
            color: $content; } } }

.lender_logo_result {
    height: 50px;
    margin: 5px 0px;
    margin-bottom: -42px;
    max-width: 150px; }

.input_wrap {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 15px;

    .pound_sign {
        position: absolute;
        top: 50%;
        left: 10px;
        font-size: 20px;
        transform: translate(0, -50%); }

    .input {
        width: calc(100% - 52px);
        padding: 10px 20px 10px 30px;
        font-size: 20px;
        outline: none;
        border: 1px solid black; } }

.slider {
    width: calc(100% - 2px);
    max-width: 100%;
    margin: 5px 0px;
    -webkit-appearance: none;
    appearance: none;
    background: #d3d3d3;
    height: 12px;
    overflow: hidden;
    border-radius: 7px;
    padding: 0;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: black;
        cursor: pointer;
        box-shadow: -2005px 0 0 2000px #999; }

    &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #04AA6D;
        cursor: pointer; }

    &::-webkit-slider-runnable-track {
        background: #d3d3d3; }

    &::-ms-fill-lower, &::-moz-range-progress {
        background: #04AA6D; } }

@media screen and ( max-width: 400px ) {
    .lender_logo_result {
        max-width: 100%;
        margin-bottom: -42px; } }

@media screen and ( max-width: 560px ) {
    .content {
        .primary {
            width: 100%;
            margin: 15px 0px; }

        .whatsapp {
            width: 100%;
            margin: 0px 0px 20px 0px; } } }
