.skyscanner {
    text-align: center;

    .wrap {
        width: 680px;
        max-width: calc(100% - 30px);
        display: inline-block;

        .heading {
            font-size: 40px;
            font-family: Tiempos;
            line-height: 40px;
            text-align: left;
            margin: 28px 0px 14px;
            font-weight: 600; }

        .sub_heading {
            font-size: 30px;
            font-family: Universal;
            margin-bottom: 40px;
            line-height: 1.4;
            text-align: left; }

        .input_wrap {
            position: relative;
            display: inline-block;
            width: 100%;
            margin-top: 15px;

            .pound_sign {
                position: absolute;
                top: 50%;
                left: 10px;
                font-size: 20px;
                transform: translate(0, -50%); }

            .input {
                width: calc(100% - 52px);
                padding: 10px 20px 10px 30px;
                font-size: 20px;
                outline: none;
                border: 1px solid black; } }

        .slider {
            width: 100%;
            margin: 5px 0px;
            -webkit-appearance: none;
            appearance: none;
            background: #d3d3d3;
            height: 12px;
            overflow: hidden;
            border-radius: 6px;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: black;
                cursor: pointer;
                box-shadow: -2005px 0 0 2000px #999; }

            &::-moz-range-thumb {
                width: 25px;
                height: 25px;
                background: #04AA6D;
                cursor: pointer; }

            &::-webkit-slider-runnable-track {
                background: #d3d3d3; }

            &::-ms-fill-lower, &::-moz-range-progress {
                background: #04AA6D; } }

        .lender_group, .lender_group_bad {
            background-color: #ddd;
            padding: 10px;
            margin: 15px -15px;

            .lender_box {
                display: inline-block;
                padding: 10px;
                background-color: white;
                margin: 10px;
                border-radius: 45px;

                .lender_logo, .lender_info {
                    display: inline-block;
                    text-align: left;
                    vertical-align: middle; }

                .lender_logo {
                    margin: 5px; }

                .lender_info {
                    margin: 0px 10px; } } }

        .lender_group_bad {
            margin-top: 30px;
            background-color: #f0f0f0;

            .lender_box {
                filter: grayscale(1); } }

        table {
            // border-collapse: collapse
            border: 1px solid black;
            margin: 15px 0px;
            width: 100%;

            thead {
                background-color: #999;

                tr {
                    width: 100%;
                    td {
                        padding: 20px 10px;
                        border: 1px solid black; } } }

            tbody {
                width: 100%;

                tr {
                    width: 100%;
                    td {
                        padding: 0px 10px;
                        border: 1px solid #999;
                        height: 50px;
                        width: 25%; } } } }

        .lender_logo {
            width: 50px; } } }
