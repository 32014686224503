.creditladder {
    font-family: "Lato", sans-serif;

    .adverse_flow {
        .further-checkbox {
            color: white; }

        .further-sub-heading {
            color: #00dfbc; }

        .further-add {
            color: #00dfbc; }

        .further-sub {
            color: #ffed00; } } }

@font-face {
    font-family: 'Poppins Light';
    src: url("../../../../fonts/Poppins-Light.ttf") format("truetype"); }

.closefield {
    font-family: "Poppins Light"; }
