@import "./_creditladder";

.toggle {
    display: inline-block;
    font-size: 13.3333px;
    color: $primary;
    border-radius: 20px;
    padding: 5px 12px 2px 12px;
    border: 1px solid $secondary;
    margin: 5px;
    line-height: normal;
    font-family: $font2;
    font-family: $font2;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.428571429;

    .toggle_key {
        width: 58px;
        display: inline-block;
        cursor: default;
        vertical-align: middle;
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 2px; }

    .toggle_value {
        display: inline-block;
        border-left: 1px solid rgba($primary, 0.1);
        padding-left: 6px;
        margin-left: 6px;
        cursor: pointer;
        vertical-align: baseline;
        margin-bottom: 2px; } }
