.loader {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: rgba(white, 0.9);
    width: 100vw;
    height: 100vh;
    z-index: 100;

    .loader1 {
        position: absolute;
        top: calc(50% - 52px);
        left: calc(50% - 52px);
        border: 2px solid black;
        border-top: 2px solid transparent;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 1.5s infinite linear; }

    .loader2 {
        position: absolute;
        top: calc(50% - 47px);
        left: calc(50% - 47px);
        border: 2px solid black;
        border-top: 2px solid transparent;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        animation: anti-spin 1.1s infinite linear; }

    .loader3 {
        position: absolute;
        top: calc(50% - 42px);
        left: calc(50% - 42px);
        border: 2px solid black;
        border-top: 2px solid transparent;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        animation: spin 1.3s infinite linear; }

    .text {
        position: absolute;
        transform: translate(-50%, -50%);
        top: calc(50% + 75px);
        left: calc(50%);
        font-size: 18px;
        font-weight: 600;
        text-align: center; } }

@keyframes spin {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }

@keyframes anti-spin {
    0% {
        transform: rotate(360deg); }
    100% {
        transform: rotate(0deg); } }
