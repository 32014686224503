#optimiser {
    .main {
        height: calc(100% - 42px);
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        text-align: center;
        height: 100%; }

    ul {
        list-style-type: none;
        padding: 0%;
        margin-top: 0px;
        margin-bottom: 0; }

    li {
        font-size: 13px; }

    .optimiser-td {
        background-color: #f0f0f0;
        margin: 4px;
        display: inline-block;
        vertical-align: top;
        border: 1px solid black;
        min-height: 272px;
        min-width: 62px;
        padding: 4px; }

    .optimiser-page {
        color: black;
        font-size: 16px;
        font-weight: 600; }

    .inline {
        display: inline-block;
        vertical-align: top; }

    .otherProperties {
        font-size: 15px;
        color: #000; }

    .optimiser-user-price {
        background-color: black;
        color: white;
        text-shadow: 0px 0px 1px black;
        margin-left: -5px;
        margin-right: -5px; }

    table {
        width: 100%;
        height: 100%;
        table-layout: fixed;
        overflow-y: hidden;
        border: none;
        border-collapse: collapse; }

    td {
        width: 50%;
        overflow: hidden;
        overflow-y: hidden;
        position: relative;
        -webkit-scroll-snap-align: start;
        scroll-snap-align: start; }

    .stars {
        margin-top: 15px; }

    .scrollTable {
        position: relative;
        width: 100%;
        overflow-x: auto;
        scroll-behavior: smooth; }

    .optimiser-table {
        overflow: auto;
        white-space: nowrap;
        scroll-behavior: smooth;
        padding-bottom: 15px; }

    .fadeLeft {
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100px;
        height: 100%;
        z-index: -1; }

    .fadeRight {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
        height: 100%;
        z-index: -1; }

    .alterPriceText {
        font-size: 50px;
        vertical-align: middle;
        z-index: 1;
        padding-top: 5px;
        font-size: 18px;
        font-weight: 600;
        vertical-align: middle;
        z-index: 1;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }

    .logo {
        width: 200px;
        position: absolute;
        top: 12%;
        left: 11%; }

    .top {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0px solid;
        border-radius: 10px; }

    .middle-container {
        display: block; }

    .section-content {
        z-index: 1;
        padding-top: 1px;
        padding-bottom: 5px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

    .uk-navbar {
        overflow: hidden;
        display: flex;
        position: relative;
        background: #f6f6f6 !important;
        padding: 0 17px; }

    .App {
        width: 100%; }

    .uk-logo {
        font-size: 1.1875rem;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        color: #666;
        text-decoration: none;
        font-family: Oswald;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 0px;
        color: #ccc; }

    .uk-navbar-item,
    .uk-navbar-nav>li>a,
    .uk-navbar-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        min-height: 80px;
        padding: 0 15px;
        font-size: 16px;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        text-decoration: none; }

    .uk-navbar-item {
        color: #666;
        min-height: 42px;
        padding: 0; }

    label {
        font-size: 22px;
        font-weight: 600;
        position: relative; }

    input {
        text-align: center;
        padding: 1px 2px;
        padding-right: 13px;
        border: 1px solid black;
        font-size: 21px;
        margin-top: 5px;
        margin-bottom: 10px;
        margin-right: -1px;
        margin-left: -1px;
        height: 36px;
        width: 60%;
        border-radius: 0; }

    #priceInput {
        padding-left: 15px;
        padding-right: 0px; }

    #locationInput {
        margin-right: 0px; }

    .score {
        color: rgb(143, 143, 143);
        font-weight: 700;
        font-size: 18px; }

    a {
        text-decoration: none;

        &:visited {}
        /* color: #00694a; */

        &:hover {
            text-shadow: 0px 0px 1px; } }

    .updateBtn {
        width: 40%;
        padding: 5px;
        margin-top: 10px;
        background-color: #02bc83;
        border: none;
        font-size: 24px;
        color: white;
        vertical-align: middle;
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .3); }

    .positionBtn {
        width: 40%;
        padding: 5px;
        margin-top: 10px;
        background-color: #02bc83;
        border: none;
        font-size: 14px;
        color: white;
        vertical-align: middle;
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .3); }

    .incrBtn {
        margin-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px; }

    .decrBtn {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: 5px;
        background-color: #bc0202; }

    .incrBtn {
        &:hover {
            transform: scale(1.02); } }

    .disableBtn {
        background-color: rgb(143, 143, 143);
        color: rgb(63, 63, 63);

        &:hover {
            transform: none; } }

    .startBtn {
        padding: 15px;
        width: 50%; }

    .scrollBtn {
        width: 100%;
        padding: 5px;
        background-color: white;
        border: none;
        border-radius: 5px;
        font-size: 20px;
        color: black;
        vertical-align: middle;
        cursor: pointer; }

    .backBtn {
        color: black; }

    .updateBtn {
        &:hover {
            transition: all 0.1s ease-in-out;
            transform: scale(1.05); } }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        opacity: 0;
        display: none; }

    input::-webkit-calendar-picker-indicator {
        opacity: 0;
        display: none; }

    .notes {
        margin-top: -10px;
        font-size: 20px;
        vertical-align: middle;
        padding-left: 15px;
        padding-right: 15px; }

    #clickNote {
        position: absolute;
        top: 50%;
        left: -100px;
        font-weight: 700;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg); }

    .note2 {
        margin-top: -10px;
        font-size: 18px; }

    .scrollToPages {
        font-size: 30px;
        vertical-align: middle;
        z-index: 2; }

    .formContent {
        padding-left: 15px;
        padding-right: 15px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 100%;
        max-height: 100%; }

    #formHouse {
        padding-top: 42px;
        padding-bottom: 26px; }

    #slide1,
    #slide2,
    #slide3 {
        -webkit-scroll-snap-align: start;
        -webkit-scroll-snap-stop: always;
        scroll-snap-stop: always;
        scroll-snap-align: start; }

    small {
        z-index: 2; }

    .firstBreak {
        display: none; }

    .secondBreak {
        display: none; }

    #comment {
        font-size: 28px;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px; }

    #fillComment {
        font-size: 20px; }

    .mainLogo {
        width: 200px; }

    .mainMenu {
        width: calc(100% - 30px); }

    .poundSign {
        position: absolute;
        top: -2px;
        left: 42px;
        color: black;
        font-size: 24px; }

    .tooltip {
        position: relative;
        display: inline-block;
        font-size: 22px;
        font-size: 14px;
        font-weight: 400;
        visibility: hidden;
        width: 120px;
        background-color: rgb(34, 34, 34);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 10px 10px;
        position: absolute;
        z-index: 1;
        top: calc(-50% - 5px);
        right: 125%;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent rgb(34, 34, 34); }

        &:hover .tooltiptext {
            visibility: visible; } }

    .negative,
    .plus {
        background-color: rgb(214, 214, 214);
        border: 1px solid black;
        border-radius: 0;
        font-size: 20px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        margin-top: 5px; }

    .negative {
        &:hover {
            background-color: #02bc83; }

        &:focus {
            outline: none; } }

    .plus {
        &:hover {
            background-color: #02bc83; } }

    .propertyForm {
        text-align: left;
        margin-left: 5%;
        margin-right: 5%; }

    .listItem {
        padding-left: 10px;
        padding-right: 10px; }

    .context {
        text-align: left;
        padding: 0px 0px 15px;

        .contextHeading {
            font-family: Tiempos;
            color: black;
            font-weight: 300;
            margin-top: 28px;
            margin-bottom: 14px;
            font-size: 22px;
            line-height: 28px;
            font-weight: 600;
            text-align: left; }

        .contextText {
            padding-bottom: 5px; } }

    .saveBtn {
        font-size: 24px;
        border-radius: 30px;
        padding-left: 30px;
        padding-right: 30px;
        border: 1px solid black; }

    .percentage {
        padding: 15px;
        padding-top: 5px; }

    .billAndTed {
        position: absolute;
        left: calc(50% - 100px);
        top: calc(50% - 75px); }

    .gifClose {
        position: absolute;
        top: 0;
        color: white;
        right: 6px;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            transform: scale(1.2); } } }

@media (max-height: 600px) {
    #optimiser {
        .optimiser-td {
            padding: 5px 30px 5px 30px; }

        // .optimiser-page
 } }        //     font-size: 20px

@media (max-width:847px) {
    #optimiser {
        table {
            width: 100%; }

        .scrollBtn {
            display: block; } } }

@media (max-height: 620px) {
    #optimiser {
        .disappearOnSmallerScreen {
            display: none; }

        label {
            font-size: 20px; }

        .note {
            font-size: 18px; }

        input {
            font-size: 20px; } } }

@media (min-height: 650px) {
    #optimiser {
        .firstBreak {
            display: block; }

        .alterPriceText {
            font-size: 22px; } } }

@media (min-height: 700px) {
    #optimiser {
        .secondBreak {
            display: block; }

        li {
            font-size: 16px; } } }

@media (min-height: 800px) {
    #optimiser {
        li {
            font-size: 18px; }

        // .optimiser-page
 } }        //     font-size: 26px

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
    #optimiser {
        .poundSign {
            margin-top: 7px; } } }

@media not all and (min-resolution:.001dpcm) {
    #optimiser {
        .poundSign {
            margin-top: 7px; } } }

@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

@keyframes antispin {
    0% {
        transform: rotate(720deg); }

    100% {
        transform: rotate(0deg); } }
