.adverse_flow {
    .modal {
        position: fixed;
        transform: translate(-50%, calc(-50% - .5px));
        top: 50%;
        left: 50%;
        width: calc(100% + 2px);
        height: 100%;
        margin-left: -1px;
        display: none;
        z-index: 100;
        max-width: inherit;
        background-color: $modal_page;

        .modalContent {
            position: fixed;
            left: 50%;
            width: 350px;
            max-width: 90%;
            padding: 10px;
            text-align: center;
            background-color: $modal_background;
            width: $width;
            max-width: calc(100% - 55px)!important;
            top: 15px;
            transform: translate(-50%, 0px);
            text-align: center;
            border: none;

            .ticker, .ticker-checked {
                border: 1px solid $button_background;
                width: 20px;
                height: 20px;
                text-align: center;
                display: inline-block;
                vertical-align: middle; }

            .ticker-checked {
                background-color: $button_background; }

            .label {
                vertical-align: middle;
                margin-left: 10px; }

            .modalX {
                font-size: 30px;
                text-align: right;
                margin-bottom: 10px;
                color: $modal_content; }

            .heading {
                font-size: 24px;
                line-height: 30px;
                text-align: left;
                font-family: $heading_font;
                color: $modal_heading; }

            .content {
                text-align: left;
                color: $modal_content;

                &:nth-last-child(1) {
                    padding-bottom: 0px; } }

            .black {
                background-color: $button_background;
                color: $button_text;
                border: none;
                border-radius: 0px;
                font-size: 18px;
                margin: 0px;
                text-transform: none;
                padding: 10px; }

            .white {
                background-color: transparent;
                color: $modal_content;
                border: 1px solid $modal_content;
                border-radius: 0px;
                font-size: 18px;
                margin: 0px;
                text-transform: none;
                padding: 10px 30px; }

            .name_input {
                width: calc(100% - 55px);
                margin: 0px 15px;
                border: 1px solid black;

                &:nth-of-type(2) {
                    margin-top: 5px; } } } } }
