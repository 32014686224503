.application {
    padding: 15px;

    .welcome {
        font-size: 45px;
        color: $heading;
        font-family: $heading_font;
        line-height: 48px; }

    .details {
        margin-top: 30px;

        .detail_heading {
            font-size: 30px;
            color: $button_background;
            line-height: 36px;
            margin-bottom: 5px; }

        .detail {
            font-size: 22px;
            color: $content;
            line-height: 26px; } }

    .applications {
        .content {
            padding: 0px;
            color: $content;
            font-size: 22px;
            line-height: 26px;
            margin: 30px 0px 15px;

            .content_heading {
                font-size: 30px;
                color: $button_background;
                line-height: 36px;
                margin-bottom: 5px; } }

        .app_heading {
            font-size: 30px;
            color: $button_background;
            line-height: 36px;
            margin-bottom: 5px; }

        .apps {
            margin-top: 15px;
            .app {
                background-color: $radio_background;
                padding: 15px;
                border-radius: 10px;
                font-family: $content_font;
                margin-bottom: 15px;

                .group {
                    display: inline-block;
                    vertical-align: middle;
                    width: calc((100% - 30px) / 3);

                    .lender_logo_result {
                        margin: 0px;
                        display: inline-block;
                        vertical-align: middle;
                        width: 100%; }

                    .app_detail {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 5px 15px;
                        width: calc(50% - 30px);
                        min-width: 85px;

                        .app_detail_heading {
                            color: $light_grey; }

                        .app_detail_text {
                            font-size: 26px;
                            line-height: 32px; } } }

                .book {
                    background-color: $button_background;
                    text-transform: none;
                    color: $button_text;
                    border-radius: 5px;
                    font-size: 18px;
                    padding: 10px;
                    margin: 0px; } } } } }
