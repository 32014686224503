#valuator, .valuator {
    button {
        font-size: 24px;
        border: white;
        background-color: white;
        margin: 15px;
        text-decoration: none;
        outline: 0;
        font-family: Universal;
        border-radius: 0; }

    #root {
        width: 100%; }

    table {
        width: 100%;
        margin-bottom: 15px;
        table-layout: fixed;
        margin-top: 10px;
        border-collapse: collapse;
        border: none;

        tbody {
            background-color: transparent;

            tr {
                border: none;

                td {
                    font-size: 16px;
                    border: none; } } } }

    .amount {
        font-size: 26px; }

    .altPriceMin, .altPriceMax {
        color: rgb(134, 134, 134); }

    .altPriceMin {
        text-align: left;
        padding-left: 0px; }

    .altPriceMax {
        text-align: right;
        padding-right: 0px; }

    .estPrice {
        text-align: center;
        font-size: 18px; }

    .priceLine {
        background-color: black;
        height: 4px;
        // margin-left: 15px
        // margin-right: 15px
        margin-bottom: 15px;
        text-align: center; }

    .priceDot {
        margin-top: -28px;
        background-color: white;
        margin-left: calc(50% - 12px);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 4px solid black; }

    .valuation {
        font-size: 36px; }

    .context {
        text-align: left;
        padding: 0px 0px 15px; }

    .contextHeading {
        font-family: Tiempos;
        color: black;
        font-weight: 300;
        margin-top: 28px;
        margin-bottom: 14px;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        text-align: left; }

    .contextText {
        padding-bottom: 5px; }

    .heading {
        font-size: 40px;
        font-size: 600;
        padding: 30px; }

    .card {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 15px;
        text-align: center;
        position: relative;
        width: 400px;
        max-width: 90%;
        background-color: white;
        border: 1px solid rgb(37, 37, 37); }

    .cardImg {
        max-width: calc(100% - 2px);
        vertical-align: middle;
        max-height: 267px; }

    .rosette {
        width: 300px;
        max-width: 90%; }

    .cardInfo {
        padding: 15px 10px 15px 10px;
        width: auto;
        border-top: none;
        margin-top: -4px;
        border-bottom: 1px solid black; }

    .cardPrice {
        position: relative;
        font-weight: 700;
        font-size: 24px;
        font-family: Tiempos;
        margin: 5px; }

    .cardHeading {
        padding-top: 15px;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 5px; }

    .cardExtraInfo {
        position: relative;
        font-weight: 500;
        font-size: 14px; }

    .cardAddress {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        font-size: 14px;
        font-weight: 500; }

    .cardSold {
        font-size: 14px;
        font-weight: 500; }

    .game {
        text-align: center; }

    .scrollImages {
        text-align: center;
        background-color: white;
        width: 400px;
        max-width: 90%;
        height: 44px;
        border: 1px solid rgb(37, 37, 37);
        border-bottom: none;
        border-top: none;
        display: inline-block; }

    .leftScroll,
    .rightScroll {
        padding-left: 15px;
        padding-right: 15px;
        background-color: rgb(214, 214, 214);
        font-size: 26px;
        margin: 0;
        cursor: pointer;
        padding-top: 4px;
        padding-bottom: 4px; }

    .leftScroll {
        float: left;
        border-right: 1px solid rgb(37, 37, 37); }

    .rightScroll {
        float: right;
        border-left: 1px solid rgb(37, 37, 37); }

    .leftScroll:hover {
 }        // background-color: #AAFF00

    .imageNumber {
        margin: 0;
        font-size: 16px;
        height: 44px;
        cursor: auto;
        padding: 4px;

        &:hover {
            background-color: transparent; } }

    .valueForm {
        text-align: center;
        position: relative; }

    .radioBtn {
        text-align: left;
        font-size: 20px;
        margin: 0px;
        padding: 10px;
        width: 100%;
        background-color: rgb(214, 214, 214);
        border-top: 1px solid rgb(37, 37, 37);
        color: black;
        text-transform: none; }

    .readyButton:hover {
        // background-color: #AAFF00
        cursor: pointer; }

    .backBtnActive:hover {
        // background-color: #AAFF00
        cursor: pointer; }

    .formTitle {
        font-size: 22px;
        font-weight: 600; }

    .formInputs {
        font-size: 16px;
        font-weight: 500;
        padding-left: 15px; }

    .searchBar {
        width: 100%; }

    .searchBtn {
        background-color: rgb(214, 214, 214);
        border: 1px solid black; }

    .postcodeInput,
    .roomNumInput {
        font-size: 24px;
        margin: 15px;
        border: 1px solid black;
        background-color: rgb(214, 214, 214); }

    .modalContainer {
        z-index: 1;
        opacity: 1;
        max-height: 100%;
        height: 100%;
        width: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.95);
        top: 50%;
        left: 51%;
        transform: translate(-50%, -50%);
        padding: 1%;
        line-height: 22px;
        box-shadow: 0 10px 10px 2px black; }

    .modal {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 49%;
        width: 95%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -webkit-scroll-snap-type: x mandatory;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth; }

    .closeBtn {
        color: white;
        position: absolute;
        top: 10px;
        right: 30px;
        font-size: 50px;

        &:hover {
            transform: scale(1.05); } }

    .numPhotos {
        position: absolute;
        top: 192px;
        right: 0px;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 2px 10px 1px 10px;
        border-radius: 10px;
        color: white;
        font-weight: 500;
        cursor: pointer;
        font-size: 16px; }

    .siteButton {
        margin: 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 2px 10px 1px 10px;
        border-radius: 10px;
        color: white;
        font-weight: 500;
        cursor: pointer;
        font-size: 16px; }

    .numPhotos:hover {
        transform: scale(1.03); }

    .thumbnail {
        display: flex;
        padding: 5px;
        margin-top: 0px;
        max-width: calc(102% - 15px);
        -webkit-scroll-snap-align: center;
        -webkit-scroll-snap-stop: always;
        scroll-snap-stop: always;
        scroll-snap-align: center; }

    .thumbnails {
        display: inline-flex; }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px; }

    ::-webkit-scrollbar-track {
        background: transparent; }

    ::-webkit-scrollbar-thumb {
        background: #3b3b3b; }

    .logo {
        width: 200px;
        position: absolute;
        top: 0;
        left: 0; }

    // .loader
    //     border: 10px solid transparent
    //     border-top: 10px solid black
    //     border-radius: 50%
    //     width: 240px
    //     height: 240px
    //     animation: spin 1s linear infinite
    //     z-index: 5

    // .loader2
    //     border: 10px solid transparent
    //     border-top: 10px solid black
    //     border-radius: 50%
    //     width: 220px
    //     height: 220px
    //     animation: antispin 1s linear infinite
    //     z-index: 5

    // .loader3
    //     border: 10px solid transparent
    //     border-top: 10px solid black
    //     border-radius: 50%
    //     width: 200px
    //     height: 200px
    //     animation: spin 0.4s linear infinite
    //     z-index: 5

    #loaderButton {
        border: 10px solid transparent;
        border-top: 10px solid black;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: spin 0.6s linear infinite;
        z-index: 5;
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 40px); }

    .loading {
        width: auto; }

    .top {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0px solid;
        border-radius: 10px; }

    .middle-container {
        display: block; }

    .section-content {
        z-index: 1;
        padding-top: 1px;
        padding-bottom: 5px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

    .finishText {
        font-size: 20px;
        font-weight: 700; }

    .finishExText {
        font-size: 20px;
        font-weight: 400; }

    .finishPrice {
        font-size: 30px;
        font-weight: 700;
        color: black;
        margin-top: 80px;
        margin-bottom: 80px; }

    .backBtn {
        font-size: 18px;
        font-family: 500;
        padding: 5px;
        cursor: pointer;
        width: 201px;
        height: 44px;
        max-width: calc(45% + 1px);
        margin: 0;
        background-color: rgb(214, 214, 214);
        border: 1px solid rgb(37, 37, 37);
        text-transform: none; }

    .backBtn2 {
        border-left: none; }

    .fas {
        font-size: 16px;
        vertical-align: middle; }

    .error {
        font-size: 20px;
        font-weight: 600;
        line-height: 80px;
        text-align: center; }

    .priceCircle {
        border: 2px solid black;
        border-radius: 50%;
        margin-left: 25%;
        margin-right: 25%;
        padding-bottom: 15%;
        padding-top: 15%;
        margin-bottom: 15px; }

    .worthText {
        text-align: left;
        font-family: Universal;
        font-size: 20px;
        padding: 14px; } }

@media (max-width: 600px) {
    #valuator {
        .amount {
            font-size: 20px; }

        .valuation {
            font-size: 28px; } } }

@media (max-width: 500px) {
    #valuator {
        .amount {
            font-size: 14px; }

        .valuation {
            font-size: 22px; } } }

@media (max-width: 420px) {
    #valuator {
        .amount {
            font-size: 12px; }

        .valuation {
            font-size: 18px; } } }

@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }

@keyframes antispin {
    0% {
        transform: rotate(720deg); }

    100% {
        transform: rotate(0deg); } }
