.loading_bar_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.6);
    z-index: 999;

    .loading_bar {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: absolute;
        width: 250px;

        .text {
            text-align: center;
            font-size: 20px;
            font-weight: 900;
            font-family: Universal; }

        .subtext {
            margin-bottom: 15px;
            text-align: center;
            font-weight: 900; }

        .bar {
            height: 5px;
            width: 100%;
            border-radius: 5px;
            transition: all 0.2s ease-in-out; } } }
