.accordion_button {
    display: inline-block;
    cursor: pointer;

    .text {
        display: inline-block; }

    .chevron {
        display: inline-block;
        position: absolute;
        transform: translate(-50%, -50%);
        right: 10px;
        top: 28px;
        transition: 0.1s all ease-in-out; } }

.accordion {
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.2s all ease-in-out; }

.flow {
    .accordion_button {
        font-family: Universal;
        background-color: transparent;
        padding: 15px 21px;
        line-height: 30px;
        border: 1px solid black;
        outline: none;
        cursor: pointer;
        // width: calc(100% - 44px)
        // max-width: 680px

        .text {
            display: inline-block; }

        .chevron {
            display: inline-block;
            position: absolute;
            transform: translate(-50%, -50%);
            right: 10px;
            top: 28px;
            transition: 0.1s all ease-in-out; }

        &:hover {
            background-color: #AAFF00; } } }
