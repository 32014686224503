.adverse_flow_steps {
    display: inline-block;
    text-align: left;
    padding: 15px;
    max-width: calc(100% - 30px);

    .content_heading {
        font-family: Tiempos;
        font-size: 28px;
        line-height: 32px;
        text-align: left;
        margin-bottom: 15px;
        margin-top: 15px; }

    .content_content {
        text-align: left;
        margin-bottom: 30px; }

    .step {
        border: 1px solid black;
        padding: 15px;
        position: relative;
        text-align: left;

        .accordion_button {
            margin: -15px;
            padding: 15px;

            &:hover {
                background-color: #eee; } }

        #step_1_button, #step_2_button, #step_3_button {
            font-size: 20px;
            width: 100%;

            #step_1_button_text, #step_2_button_text, #step_3_button_text {
                padding-right: 40px;
                line-height: 26px; } }

        #step_1, #step_2, #step_3 {
            text-align: center; } }

    .subheading {
        padding: 0px 15px;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
        color: black; }

    ul {
        li {
            line-height: 40px;
            list-style-image: url("../../img/icon-tick.svg");
            padding-left: 10px; } }

    .result_button {
        background-color: black;
        color: white;
        text-transform: none;
        width: 100%;
        font-size: 18px;
        border-radius: 0px;
        margin: 0px 15px;
        margin-bottom: 20px;
        padding: 12px 0px;
        border: 1px solid black;
        cursor: pointer;
        height: 50px; }

    .alt_result_button {
        background-color: #25D366;
        color: white;
        text-transform: none;
        width: 100%;
        font-size: 18px;
        border-radius: 0px;
        margin: 0px 15px;
        margin-bottom: 20px;
        padding: 12px 0px;
        margin-top: 20px;
        border: none;
        cursor: pointer; }

    // .table_acc_button
    //     border-bottom: 1px solid $content
    //     display: inline-block

    // .lender_logo_result
    //     height: 50px
    //     margin: 5px 0px
    //     margin-bottom: -15px
 }    //     max-width: 150px
