$background: white;
$heading: #223843;
$content: #2d5367;
$back: #2d5367;

$radio_background: white;
$radio_text: #2d5367;

$input_background: white;
$input_text: #2d5367;

$button_background: #0970ce;
$button_text: #f6fafd;

$heading_font: Lato, sans-serif;
$content_font: 'Open Sans',sans-serif;

$disabled: #b4b4b4;
$greyed_out: #4e4e4e;
$light_grey: #4e4e4e;

$progress: #0970ce;
$progress_greyed: #0970ce20;

$modal_page: #2d5367;
$modal_background: white;
$modal_heading: #223843;
$modal_content: #2d5367;

$sub_color: #2d5367;
$info_color: white;
$active_color: #00ccc1;

$collection_row1: #2d5367;
$collection_row2: #00ccc1;
$collection_border: #00ccc1;
$collection_add: #2d5367;

$result_grey_box: #b4b4b4;

$width: 680px;

.portify {
    width: 100vw;
    min-height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";

        .heading {
            font-size: 44px;
            line-height: 52px;
            font-weight: 900; }

        .content {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px; }

        .radio {
            font-weight: 500; }

        .navbar {
            .back {
                font-weight: 500; } }

        .modal {
            .modalContent {
                .content {
                    font-size: 15px; } } }

        .info {
            font-size: 18px; }

        .input_contain {
            .capBtn {
                border-radius: 100px;
                text-transform: capitalize;
                padding: 15px 50px; } }

        .radio.small {
            width: 187px; }

        .section_divider {
            .section_overview {}

            .capBtn {
                border-radius: 100px;
                text-transform: capitalize;
                padding: 15px 50px; } } } }
