.section_divider {
    .subheading {
        color: $light_grey; }

    .section_overview {
        border: 1px solid $radio-text;
        margin: 0px 15px;
        padding: 15px;
        margin-top: -1px;

        &:nth-child(1) {
            margin-top: 0px; }

        .icon {
            width: 35px;
            display: inline-block;
            vertical-align: top;
            text-align: center;
            padding-right: 15px;
            margin-top: 5px; }

        .text {
            width: calc(100% - 50px);
            display: inline-block;
            vertical-align: top;

            .top {
                font-size: 18px;
                line-height: 30px;
                font-family: $content_font; }

            .bottom {
                font-family: $content_font;

                .timeframe {
                    color: $light_grey; } } }

        &.complete {
            border: 1px solid $light_grey;

            .text {
                color: $light_grey; } } }

    .capBtn {
        margin: 15px; } }
