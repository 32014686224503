$background: white;
$heading: black;
$content: black;
$back: #999;

$radio_background: white;
$radio_text: black;

$input_background: white;
$input_text: black;

$button_background: black;
$button_text: white;

$heading_font: Tiempos;
$content_font: Universal;

$disabled: #dddddd;
$greyed_out: grey;
$light_grey: #999999;

$progress: black;
$progress_greyed: #e8e8e8;

$modal_page: #e8e8e8;
$modal_background: white;
$modal_heading: black;
$modal_content: black;

$sub_color: black;
$info_color: grey;
$active_color: black;

$collection_row1: black;
$collection_row2: grey;
$collection_border: grey;
$collection_add: black;

$result_grey_box: #f0f0f0;

$width: 680px;

.encompass {
    width: 100%;
    min-height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";
        @import "../../Application/_application";

        .radio.small {
            width: 196px; } } }
