@font-face {
    font-family: "ITC Grouch";
    src: url("//db.onlinewebfonts.com/t/537c29c4b78689a51f1c48cd802a503f.eot");
    src: url("//db.onlinewebfonts.com/t/537c29c4b78689a51f1c48cd802a503f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/537c29c4b78689a51f1c48cd802a503f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/537c29c4b78689a51f1c48cd802a503f.woff") format("woff"), url("//db.onlinewebfonts.com/t/537c29c4b78689a51f1c48cd802a503f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/537c29c4b78689a51f1c48cd802a503f.svg#ITC Grouch") format("svg"); }

@font-face {
    font-family: "Apercu Pro";
    src: url("//db.onlinewebfonts.com/t/40ea291a03f81ef88a049f19415be431.eot");
    src: url("//db.onlinewebfonts.com/t/40ea291a03f81ef88a049f19415be431.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/40ea291a03f81ef88a049f19415be431.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/40ea291a03f81ef88a049f19415be431.woff") format("woff"), url("//db.onlinewebfonts.com/t/40ea291a03f81ef88a049f19415be431.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/40ea291a03f81ef88a049f19415be431.svg#Apercu Pro") format("svg"); }

$background: #fdf7f0;
$heading: #374f4c;
$content: #3e3e42;
$back: #999;

$radio_background: #f3dcc4;
$radio_text: $heading;

$input_background: #f3dcc4;
$input_text: $heading;

$button_background: #efa07c;
$button_text: $content;

$heading_font: "ITC Grouch";
$content_font: "Apercu Pro";

$disabled: #dddddd;
$greyed_out: grey;
$light_grey: $content;

$progress: $heading;
$progress_greyed: #ede5dc;

$modal_page: #fdf7f0;
$modal_background: #374f4c;
$modal_heading: white;
$modal_content: white;

$sub_color: black;
$info_color: grey;
$active_color: $heading;

$collection_row1: black;
$collection_row2: grey;
$collection_border: grey;
$collection_add: black;

$result_grey_box: #f0f0f0;

$width: 740px;

.nude {
    width: 100%;
    min-height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";
        @import "../../Application/_application";

        .lender_table {
            border: 1px solid #f3dcc4;
            background-color: white;

            thead {
                tr {
                    background-color: #f3dcc4;
                    color: $content; } }

            tbody {
                .accordion_tr {
                    border-color: #f3dcc4;

                    .product_container {
                        .product_bubble {
                            border-color: #f3dcc4;
                            color: $content;

                            .apply_btn {
                                background-color: #efa07c; } } } }

                tr {
                    td {
                        .apply_btn_alt {
                            background-color: transparent; } } } } }

        .section_divider {
            .section_overview {
                background-color: #f3dcc4;
                color: $heading;
                .text {
                    .top {
                        font-family: $heading_font; } } }

            .heading {
                font-size: 46px;
                letter-spacing: -1px; }

            .content {
                font-size: 16px; } }

        .radio.small {
            width: 196px;
            background-color: #efa07c;
            color: #3e1907;
            border-radius: 25px;
            text-align: center;
            align-content: center;
            border: none;
            text-transform: capitalize; }

        .capBtn {
            line-height: 46px;
            padding: 0px;
            width: 120px;
            height: 46px;
            background-color: #efa07c;
            color: #3e1907;
            border-radius: 25px;
            text-align: center;
            align-content: center;
            font-size: 18px;
            border: none;
            text-transform: capitalize; }

        .input_contain {
            .capBtn {
                line-height: 46px;
                padding: 0px;
                width: 120px;
                height: 46px;
                background-color: #efa07c;
                color: #3e1907;
                border-radius: 25px;
                text-align: center;
                align-content: center;
                font-size: 18px;
                border: none;
                text-transform: none;
                text-transform: capitalize;

                &:disabled {
                    border: none; } } }

        .modal {
            .modalContent {
                .label {
                    color: white; }

                .capBtn {
                    line-height: 46px;
                    padding: 0px!important;
                    width: 120px;
                    height: 46px;
                    background-color: #efa07c;
                    color: white;
                    border-radius: 25px;
                    text-align: center;
                    align-content: center;
                    font-size: 18px;
                    border: none;
                    text-transform: capitalize; } } } } }
