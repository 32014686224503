.creditladder.step12 {
    .adverse_credit_container {
        .adverse-credit {
            width: 1050px;

            .accordion_wrap {
                border: 1px solid white;

                .accordion_button {
                    text-align: left;
                    padding: 15px;
                    display: block;
                    font-family: Lato, sans-serif;

                    .text {
                        font-size: 22px; } }

                .accordion {
                    padding: 0px 15px 0px;

                    .slider_label {
                        color: #ffed00!important; }

                    #valuator, .valuator {
                        font-size: 18px;

                        .loader {
                            .loader1, .loader2, .loader3 {
                                border-color: white!important;
                                border-top-color: transparent!important; } }

                        .context {
                            font-family: "Lato", sans-serif;
                            margin-bottom: 30px;

                            .contextHeading {
                                font-family: "Lato", sans-serif;
                                color: #ffed00; } }

                        .priceLine {
                            background-color: #00dfbc; }

                        .priceDot {
                            border: 4px solid #00dfbc;
                            background-color: #141432; }

                        .altPriceMin {
                            color: #00dfbc; }

                        .altPriceMax {
                            color: #00dfbc; }

                        .cardInfo {
                            border: 1px solid white;
                            color: #141432;
                            font-family: "Lato", sans-serif; }

                        .cardPrice {
                            font-family: "Lato", sans-serif; }

                        .backBtn {
                            border-color: white;
                            background-color: #5e00fa;
                            color: white;
                            font-family: "Lato", sans-serif; }

                        .scrollImages {
                            border-color: white!important; }

                        .leftScroll, .rightScroll {
                            border-color: white!important;
                            background-color: #5e00fa;
                            color: white;
                            font-family: "Lato", sans-serif; }

                        .cardHeading {
                            color: #ffed00; }

                        .card {
                            border-color: white; }

                        .imageNumber {
                            font-family: "Lato", sans-serif; }

                        .siteButton {
                            a {
                                font-family: "Lato", sans-serif; } }

                        .valueForm {
                            .radioBtn {
                                background-color: #5e00fa;
                                border-color: white!important;
                                color: white!important;
                                font-family: "Lato", sans-serif; } }

                        .finishPrice {
                            color: #ffed00; } }

                    #optimiser {
                        font-family: "Lato", sans-serif!important;

                        .optimiser-user-price {
                            background-color: #5e00fa; }

                        .loader {
                            .loader1, .loader2, .loader3 {
                                border-color: white!important;
                                border-top-color: transparent!important; } }

                        .scrollTable {
                            font-family: "Lato", sans-serif; }

                        .score {
                            color: #00dfbc; }

                        .inline {

                            .optimiser-td {
                                border: 1px solid #5e00fa;
                                padding: 4px 4px 0px; }

                            .optimiser-page {
                                color: #ffed00; }

                            .optimiser-td, .otherProperties {
                                a {
                                    color: #141432!important; } } } }

                    .toolSearchHeading {
                        margin-top: -30px;
                        font-size: 18px; }

                    .searchButton {
                        background-color: #5e00fa;
                        color: white;
                        cursor: pointer;
                        font-size: 20px;
                        padding: 10px;
                        width: 150px;
                        margin: 30px 5px 15px;
                        border-radius: 24px;

                        &:disabled {
                            background-color: #f0f0f0;
                            border: 1px solid #f0f0f0;
                            cursor: not-allowed; } } } }

            .additional_question {
                margin-top: 25px;
                font-size: 24px;

                select {
                    font-size: 20px;
                    padding: 10px 20px;
                    margin-bottom: 10px;
                    margin-top: 10px; } }

            .heading {
                line-height: 40px; }

            .sticky {
                background-color: #141432!important;

                .input_wrap {
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    margin-top: 0px; }

                .pound_sign {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    font-size: 20px;
                    transform: translate(0, -50%);
                    color: black;
                    font-weight: 600; }

                .input {
                    width: calc(100% - 52px);
                    padding: 10px 20px 10px 30px;
                    font-size: 20px;
                    outline: none;
                    border: 1px solid black; }

                .slider_label {
                    color: #ffed00!important; } } }

        .slider {
            width: 100%;
            margin: 5px 0px;
            -webkit-appearance: none;
            appearance: none;
            background: #d3d3d3;
            height: 12px;
            overflow: hidden;
            border-radius: 6px;
            outline: none;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #00dfbc;
                cursor: pointer;
                box-shadow: -2005px 0 0 2000px #ffed00; }

            &::-moz-range-thumb {
                width: 25px;
                height: 25px;
                background: #04AA6D;
                cursor: pointer; }

            &::-webkit-slider-runnable-track {
                background: white; }

            &::-ms-fill-lower, &::-moz-range-progress {
                background: #04AA6D; } }

        .lender_group, .lender_group_bad {
            background-color: white;
            padding: 10px;
            margin: 15px -15px;

            .lender_number {
                color: #141432; }

            .lender_box {
                display: inline-block;
                padding: 10px;
                background-color: white;
                margin: 10px;
                border-radius: 53px;
                text-align: left;
                width: 250px;
                color: #141432;
                border: 1px solid #00dfbc;

                .accordion {
                    .lender_reasons {
                        color: #838383;
                        font-size: 12px; }

                    .lenderInfo {
                        .accordion_button {
                            .text {
                                color: #00dfbc;
                                font-size: 12px;
                                text-underline-offset: 2px;
                                text-decoration: underline; } } } }

                .lender_logo, .lender_info {
                    display: inline-block;
                    text-align: left;
                    vertical-align: middle; }

                .lender_logo {
                    margin: 5px; }

                .lender_info {
                    margin: 0px 10px;

                    .sub_lender_accordion {

                        .accordion_button {
                            .text {
                                color: #00dfbc;
                                font-size: 12px;
                                text-underline-offset: 2px;
                                text-decoration: underline; } } } } }

            .lender_number_reject {
                color: white; } }

        .lender_group_bad {
            margin-top: 30px;
            background-color: #f0f0f0;

            .lender_box {
                filter: grayscale(1); } }

        table {
            // border-collapse: collapse
            border: 1px solid black;
            margin: 15px 0px;
            width: 100%;

            thead {
                background-color: #999;

                tr {
                    width: 100%;
                    td {
                        padding: 20px 10px;
                        border: 1px solid black; } } }

            tbody {
                width: 100%;

                tr {
                    width: 100%;
                    td {
                        padding: 0px 10px;
                        border: 1px solid #999;
                        height: 50px;
                        width: 25%; } } } }

        .lender_logo {
            width: 50px; } } }
