$background: white;
$heading: #bed600;
$content: #161616;
$back: #161616;

$radio_background: #161616;
$radio_text: white;

$input_background: white;
$input_text: #161616;

$button_background: #161616;
$button_text: white;

$heading_font: "source sans pro","helvatica","arial","sans-serif";
$content_font: 'Open Sans',sans-serif;

$disabled: #b4b4b4;
$greyed_out: #4e4e4e;
$light_grey: #4e4e4e;

$progress: #bed600;
$progress_greyed: #bed60020;

$modal_page: #161616;
$modal_background: #161616;
$modal_heading: #bed600;
$modal_content: white;

$sub_color: #161616;
$info_color: white;
$active_color: #bed600;

$collection_row1: #2d5367;
$collection_row2: #bed600;
$collection_border: #bed600;
$collection_add: #2d5367;

$result_grey_box: #b4b4b4;

$width: 680px;

.nrla {
    width: 100vw;
    min-height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";

        .heading {
            letter-spacing: -1px;
            font-size: 2.5em;
            font-weight: 900; }

        .radio.small {
            width: 187px; }

        .alt_result_button {
            border: 1px solid black; }

        .deposit_changer {
            border-color: white;

            .deposit {
                color: white;
                border-color: white;

                &.active {
                    background-color: $heading;
                    color: black; } } }

        .lender_table {
            thead {
                tr {
                    background-color: white;
                    color: black;
                    border: 1px solid black; } } }

        .section_divider {
            .section_overview {
                border-color: black; } } } }
