$background: #141432;
$heading: #ffed00;
$content: white;
$back: white;

$radio_background: white;
$radio_text: #141432;

$input_background: white;
$input_text: #141432;

$button_background: #5e00fa;
$button_text: white;

$heading_font: Lato, sans-serif;
$content_font: Lato, sans-serif;

$disabled: #b4b4b4;
$greyed_out: #4e4e4e;
$light_grey: #b4b4b4;

$progress: #ffed00;
$progress_greyed: #5e00fa;

$modal_page: #141432;
$modal_background: white;
$modal_heading: #141432;
$modal_content: #141432;

$sub_color: white;
$info_color: white;
$active_color: #00dfbc;

$collection_row1: white;
$collection_row2: #00dfbc;
$collection_border: #00dfbc;
$collection_add: white;

$result_grey_box: white;

$width: 1050px;

.creditladder {
    width: 100vw;
    min-height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";
        @import "../../Application/_application";

        .heading {
            font-size: 30px;
            font-weight: 500; }

        .content, .subheading {
            font-size: 24px;
            font-weight: 500;
            line-height: 28px; }

        .subheading {
            font-weight: 700;
            letter-spacing: 1px;
            color: $heading; }

        .radio {
            font-weight: 500; }

        .navbar {
            .back {
                font-weight: 500; } }

        .modal {
            .modalContent {
                .content {
                    font-size: 15px; } } }

        .info {
            font-size: 18px; }

        .input_contain {
            .capBtn {
                border-radius: 100px;
                font-weight: 500;
                padding: 12px 60px;
                border: 1px solid $content; } }

        .radio.small {
            border-radius: 100px;
            font-weight: 500;
            padding: 12px 60px!important;
            border: 1px solid $content;
            width: 263px;
            text-align: center; }

        ul {
            li {
                filter: invert(100%) saturate(3);
                color: black; } }

        .result_button {
            border-radius: 100px;
            font-weight: 500;
            padding: 12px 60px;
            border: 1px solid $content; }

        .alt_result_button {
            border-radius: 100px;
            font-weight: 500;
            padding: 12px 60px;
            background-color: $button_text;
            color: $button_background;
            border: 1px solid $button_background; }

        .sub_button {
            margin-top: 10px; }

        .section_divider {
            .section_overview {
                filter: invert(100%);

                .text {
                    .bottom {
                        .timeframe {
                            color: $light_grey; } } } }

            .subheading {
                color: $active_color; }

            .capBtn {
                border-radius: 100px;
                font-weight: 500;
                padding: 12px 60px;
                border: 1px solid $content; } }

        .protection_wrap, .gi_wrap {
            border-color: $collection_border;

            .protection, .gi {
                color: $content;

                .loader {
                    background-color: rgba($background, 0.8);

                    .loader1, .loader2, .loader3 {
                        border-color: $content;
                        border-bottom-color: transparent; } } } }

        .applyButton {
            background-color: $button_background;
            color: $button_text; }

        .figure {
            color: $active_color; }

        .applied_wrap {
            background-color: $background;

            .applied {
                .product {
                    color: $background; }

                .next_heading {
                    color: $active_color;
                    font-weight: 900;
                    font-family: $heading_font; }

                .content {
                    color: $background;
                    font-size: 20px; }

                .primary {
                    background-color: $button_background; } } }

        .insurance_table {

            .loader {
                    background-color: rgba($background, 0.8);

                    .loader1, .loader2, .loader3 {
                        border-color: $content;
                        border-bottom-color: transparent; } }

            tbody {
                tr {
                    td {
                        color: $heading; }

                    &:nth-child(1), &:nth-child(3) {
                        td {
                            color: white; } }

                    td {
                        &:nth-child(1) {
                            color: white; } } } } }

        .content {
            .primary {
                background-color: $button_background;
                color: $button_text; } }

        .result_table {
            thead {
                tr {
                    td {
                        color: $background; } } }

            tbody {
                tr {
                    td {
                        &:nth-last-child(1) {
                            button {
                                background-color: $button_background;
                                color: $button_text;

                                &:disabled {
                                    background-color: #c0c0c0;
                                    color: #808080; } } } } } } }

        .stats_table {
            thead {
                color: $heading; }

            tbody {
                tr {
                    td {
                        .stat_div {
                            &:focus-within {
                                .stat_label_start, .stat_label_end {
                                    outline-color: $button_background; } }

                            .stat_label_start {
                                background-color: $button_background;
                                border-color: $button_background;
                                outline-color: $button_background; }

                            .stat_input_div {
                                border-color: $button_background;
                                outline-color: $button_background;

                                &:focus-within {
                                    outline-color: $button_background; }

                                .stat_input {
                                    color: white; } }

                            .stat_label_end {
                                background-color: $button_background;
                                border-color: $button_background;
                                outline-color: $button_background; } } } } } } } }



