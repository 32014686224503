@font-face {
    font-family: 'Poppins Light';
    src: url("../../../fonts/Poppins-Light.ttf") format("truetype"); }

@font-face {
    font-family: 'Poppins Medium';
    src: url("../../../fonts/Poppins-Medium.ttf") format("truetype"); }

@font-face {
    font-family: 'Poppins Regular';
    src: url("../../../fonts/Poppins-Bold.ttf") format("truetype"); }

$background: white;
$heading: #001930;
$content: #001930;
$back: #999;

$radio_background: white;
$radio_text: #001930;

$input_background: white;
$input_text: #001930;

$button_background: #001930;
$button_text: white;

$heading_font: "Poppins Regular";
$content_font: 'Poppins Light';

$disabled: #dddddd;
$greyed_out: grey;
$light_grey: #001930;

$progress: #001930;
$progress_greyed: #e5b4aa;

$modal_page: #e8e8e8;
$modal_background: white;
$modal_heading: #001930;
$modal_content: #001930;

$sub_color: black;
$info_color: grey;
$active_color: #001930;

$collection_row1: #001930;
$collection_row2: grey;
$collection_border: grey;
$collection_add: #001930;

$result_grey_box: #f0f0f0;

$width: 920px;

.closefield {
    width: 100%;
    min-height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";
        @import "./results/_results";
        @import "./_divider";
        @import "../steps/_steps";
        @import "../../Application/_application";

        button {
            font-family: "Poppins Medium"; }

        .radio.small {
            width: 169px;
            font-size: 16px;
            background-color: #e5b4aa;
            border-radius: 10px;
            border: none;
            text-transform: capitalize; }

        .section_divider {
            .heading {
                font-size: 36px; } }

        .capBtn {
            font-size: 16px;
            background-color: #e5b4aa;
            border-radius: 10px;
            border: none;
            text-transform: capitalize;
            padding-right: 53px;
            padding-left: 53px; }

        .input_contain {
            .capBtn {
                font-size: 16px;
                background-color: #e5b4aa;
                border-radius: 10px;
                border: none;
                text-transform: capitalize;
                padding-right: 53px;
                padding-left: 53px;

                &:disabled {
                    border: none; } } }

        .deposit_changer {
            .deposit {
 } }                // background-color: #e5b4aa

        .content {
            .primary {
                background-color: #e5b4aa;
                border: none;
                border-radius: 10px;
                color: white; }

            .whatsapp {
                border-radius: 10px;
                border: none; } } } }
