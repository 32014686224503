$width_top: 1200px;

.milo_wrap {
    width: 100%;
    // height: 100%

    .milo {
        width: $width_top;
        max-width: 100%;
        margin: auto;

        .cta {
            .header {
                display: inline-block;
                vertical-align: middle;
                margin: 15px;
                max-width: calc( 100% - 30px);
                width: calc(#{$width_top} / 2 - 30px);
                text-align: left;

                .top {
                    font-size: 60px;
                    line-height: 68px;
                    margin-bottom: 20px; }

                .bottom {
                    font-size: 20px;
                    line-height: 32px; }

                .apply_btn {
                    margin-top: 40px;
                    border: none;
                    padding: 15px 30px;
                    border-radius: 0px;
                    font-size: 20px;
                    background-color: black;
                    color: white;
                    text-transform: uppercase; } }


            .images {
                display: inline-block;
                vertical-align: middle;
                margin: 15px;
                max-width: calc( 100% - 30px);
                width: calc(#{$width_top} / 2 - 30px); } } } }

@import "./styles/_creditladder";
@import "./styles/_sprung";
